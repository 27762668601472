import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren, } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule, } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router, RouterLink, Data, } from '@angular/router';

import { Breadcrumb, BreadcrumbComponent } from 'components/breadcrumb/breadcrumb.component';
import { WrapperTableComponent, } from 'components/wrapper-table';
import {
    BrandSelectOptions,
    Campaign,
    CampaignList,
    CampaignSubtype,
    CampaignType,
    IAdvertiser,
    Advertiser,
    ICampaign,
    ITradeShow,
    TradeShowReport,
    TradeShowReportList, IUser,
} from 'model';
import {
    catchError, combineLatest, defaultIfEmpty, map, Observable,
    ReplaySubject, Subscription, mergeMap, EMPTY,
} from 'rxjs';
import { AdvertiserService, UserService, CampaignService, TradeShowReportsResponse } from 'services';
import { AsTypePipe, NumberPipe, PercentagePipe, } from 'util';

export enum PastCampaignsPageType {
    Advertiser = 'advertiser',
    SalesRep = 'sales-rep',
}

type RouteData = {
    pageType: PastCampaignsPageType;
}

@Component({
    selector: 'app-past-campaigns',
    standalone: true,
    imports: [
        AsTypePipe,
        NumberPipe,
        PercentagePipe,
        CommonModule,
        MatTabsModule,
        MatDividerModule,
        BreadcrumbComponent,
        WrapperTableComponent,
        MatTableModule,
        MatSortModule,
        RouterLink,
        MatIconModule,
    ],
    templateUrl: './past-campaigns.component.html',
    styleUrl: './past-campaigns.component.scss'
})
export class PastCampaignsComponent implements OnInit, OnDestroy {
    protected selectedIndex = 0;

    protected subscriptions: Subscription[] = [];

    #breadcrumbsSubject: ReplaySubject<Breadcrumb[]> = new ReplaySubject<Breadcrumb[]>(1);
    protected breadcrumbs$: Observable<Breadcrumb[]> = this.#breadcrumbsSubject.asObservable();

    protected emailColumns = ['name', 'leadCount', 'brand', 'date'];
    protected socialMediaColumns = ['name', 'brand', 'audienceNetworkStartDate', 'audienceNetworkStopDate', 'status', 'uniqueClicks', 'uniqueClickThroughRate', 'reportingCompleteDateMillis'];
    protected webColumns = ['name', 'brand', 'audienceNetworkStartDate', 'audienceNetworkStopDate', 'status', 'impressions', 'reportingCompleteDateMillis'];
    protected videoSeriesColumns = ['name', 'brand', 'audienceNetworkStartDate', 'audienceNetworkStopDate', 'status', 'audienceNetworkViews', 'reportingCompleteDateMillis'];
    protected ebookColumns = ['name', 'leadCount', 'brand', 'date'];
    protected webinarColumns = ['name', 'leadCount', 'brand', 'date'];
    protected printColumns = ['name', 'distribution', 'brand', 'date'];
    protected boothScanColumns = ['name', 'leadCount', 'tradeShowFullName', 'date'];
    protected gpSlColumns = ['name', 'categoryName', 'recipients', 'tradeShowFullName', 'publishDate'];
    protected facilitiesAndInfraColumns = ['name', 'tradeShowFullName', 'publishDate'];

    #campaignsSubject: ReplaySubject<CampaignList> = new ReplaySubject<CampaignList>(1);
    protected campaigns$: Observable<CampaignList> = this.#campaignsSubject.asObservable();

    protected emailCampaigns$: Observable<CampaignList> = this.campaigns$.pipe(
        map((campaigns: CampaignList) => campaigns.filterByCampaignType(CampaignType.Email))
    );
    protected facebookCampaigns$: Observable<CampaignList> = this.campaigns$.pipe(
        map((campaigns: CampaignList) => campaigns
            .filterByCampaignType(CampaignType.Facebook)
            .filter((campaign: Campaign) => campaign.hasReportingCompleteDate())
        )
    );
    protected linkedInCampaigns$: Observable<CampaignList> = this.campaigns$.pipe(
        map((campaigns: CampaignList) => campaigns
            .filterByCampaignType(CampaignType.LinkedIn)
            .filter((campaign: Campaign) => campaign.hasReportingCompleteDate())
        )
    );
    protected targetedBrandingCampaigns$: Observable<CampaignList> = this.campaigns$.pipe(
        map((campaigns: CampaignList) => campaigns
            .filterByCampaignType(CampaignType.TargetedBranding)
            .filter((campaign: Campaign) => campaign.hasImpressions())
        )
    );
    protected videoExposureCampaigns$: Observable<CampaignList> = this.campaigns$.pipe(
        map((campaigns: CampaignList) => campaigns
            .filterByCampaignType(CampaignType.VideoExposure)
            .filter((campaign: Campaign) => campaign.hasImpressions())
        )
    );
    protected videoSeriesCampaigns$: Observable<CampaignList> = this.campaigns$.pipe(
        map((campaigns: CampaignList) => campaigns
            .filterByCampaignType(CampaignType.VideoExposure)
            .filter((campaign: Campaign) => campaign.hasViews())
        )
    );
    protected ebookCampaigns$: Observable<CampaignList> = this.campaigns$.pipe(
        map((campaigns: CampaignList) => campaigns.filterByCampaignTypeAndSubtype(
            CampaignType.Web,
            CampaignSubtype.Playbook, CampaignSubtype.PMGRegForm, CampaignSubtype.Custom
        ))
    );
    protected webinarCampaigns$: Observable<CampaignList> = this.campaigns$.pipe(
        map((campaigns: CampaignList) => campaigns.filter((campaign: Campaign) => campaign.isWebinar()))
    );
    protected printCampaigns$: Observable<CampaignList> = this.campaigns$.pipe(
        map((campaigns: CampaignList) => campaigns.filterByCampaignType(CampaignType.Print))
    );
    protected tradeShowCampaigns$: Observable<CampaignList> = this.campaigns$.pipe(
        map((campaigns: CampaignList) => campaigns.filterByCampaignType(CampaignType.TradeShow))
    );

    private tradeShowReportsSubject: ReplaySubject<TradeShowReportsResponse> = new ReplaySubject<TradeShowReportsResponse>(1);
    protected tradeShowReports$: Observable<TradeShowReportsResponse> = this.tradeShowReportsSubject.asObservable();

    protected gamePlanTradeShowReports$: Observable<TradeShowReportList> = this.tradeShowReports$.pipe(
        map((campaigns: TradeShowReportsResponse) => new TradeShowReportList(...campaigns.gamePlan))
    );

    protected secondLookTradeShowReports$: Observable<TradeShowReportList> = this.tradeShowReports$.pipe(
        map((campaigns: TradeShowReportsResponse) => new TradeShowReportList(...campaigns.secondLook))
    );

    protected facilitiesAndInfrastructureTradeShowReports$: Observable<TradeShowReportList> = this.tradeShowReports$.pipe(
        map((campaigns: TradeShowReportsResponse) => new TradeShowReportList(...campaigns.facilitiesAndInfrastructure))
    );

    protected advertiser$!: Observable<IAdvertiser>;

    protected tradeShows: ITradeShow[] = [];
    protected tradeShowsSubject: ReplaySubject<ITradeShow[]> = new ReplaySubject<ITradeShow[]>(1);
    protected tradeShows$: Observable<ITradeShow[]> = this.tradeShowsSubject.asObservable().pipe(defaultIfEmpty([]));
    protected tradeShowSelectOptions$: Observable<{ id: any, name: string }[]> = this.tradeShows$.pipe(
        map((tradeShows: ITradeShow[]) => {
            const tradeShowOptions = tradeShows
                .map((tradeShow: ITradeShow) => ({ id: tradeShow.fullName, name: tradeShow.name }));
            return [{ id: -1 as any, name: '' }].concat(tradeShowOptions);
        }),
    );
    protected tradeShowSelectOptionsLimited$: Observable<{ id: any, name: string }[]> = this.tradeShows$.pipe(
        map((tradeShows: ITradeShow[]) => {
            const tradeShowOptions = tradeShows
                .filter((tradeShow: ITradeShow) => ![2, 6, 8, 9, 11, 12, 15, 16].includes(tradeShow.id))
                .map((tradeShow: ITradeShow) => ({ id: tradeShow.fullName, name: tradeShow.name }));
            return [{ id: -1 as any, name: '' }].concat(tradeShowOptions);
        })
    );

    campaignLinkFormatter = (campaign: Campaign) => {
        return campaign.isCampaignViewable()
            ? `/advertiser/${campaign.aid}/past-campaigns/campaign/${campaign.cid}`
            : null;
    };

    // set 'brands' property for brand select options filtering
    brands: { id: number, name: string }[] = BrandSelectOptions;

    @ViewChildren(WrapperTableComponent) tableComponents!: QueryList<WrapperTableComponent<Campaign>>;
    @ViewChildren(MatSort) sorts!: QueryList<MatSort>;

    @Input()
    set advertiserId(advertiserId: number | null) {
        if (!advertiserId) {
            return;
        }
        const errorHandler = (error: any) => {
            if (error.status === 404) {
                void this.router.navigate(
                    ['/404'],
                    { state: { error: `Advertiser with id ${advertiserId} not found` } }
                );
            }
            throw error;
        }

        const dataSubscription = combineLatest([
            this.campaignService.getPastCampaigns(advertiserId).pipe(catchError(errorHandler)),
            this.campaignService.getTradeShowReports(advertiserId).pipe(catchError(errorHandler)),
            this.campaignService.getTradeShows()
        ])
            .subscribe(([campaigns, tradeShowCampaigns, tradeShows]: [ICampaign[], TradeShowReportsResponse, ITradeShow[]]) => {
                this.#campaignsSubject.next(new CampaignList(...campaigns));
                this.tradeShowReportsSubject.next(tradeShowCampaigns);
                this.tradeShowsSubject.next(tradeShows);
                this.tradeShows = tradeShows;
            });
        this.subscriptions.push(dataSubscription);

        this.advertiser$ = this.advertiserService.getAdvertiser(advertiserId).pipe(catchError(errorHandler));
        const advertiserBreadcrumbSubscription = this.advertiser$.subscribe((advertiser: IAdvertiser) => {
            this.#breadcrumbsSubject.next([
                { label: advertiser.name, url: `/advertiser/${advertiser.aid}/upcoming-campaigns` },
                { label: 'Past Campaigns', url: `/advertiser/${advertiser.aid}/past-campaigns` },
            ]);
        });
        this.subscriptions.push(advertiserBreadcrumbSubscription);
    }

    protected readonly Campaign = Campaign;
    protected readonly TradeShowReport = TradeShowReport;

    constructor(
        private campaignService: CampaignService,
        private advertiserService: AdvertiserService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        const routeSubscription = this.route.fragment.subscribe((fragment: string | null) => {
            if (!fragment) { return; }
            const index = Number(fragment.replace('tab', ''));
            if (!isNaN(index)) {
                this.selectedIndex = index - 1;
            }
        });
        this.subscriptions.push(routeSubscription);

        const salesRepViewSubscription = this.route.data.pipe(
            mergeMap((data: RouteData | Data) => {
                const pageType = (data as RouteData).pageType;
                if (!pageType || pageType !== PastCampaignsPageType.SalesRep) {
                    return EMPTY;
                }
                return this.userService.getLoggedInUser()
            }),
            map(user => user.advertisers.map(advertiser => advertiser.aid)),
            mergeMap(aids => combineLatest([
                this.advertiserService.getAdvertisers(...aids),
                this.campaignService.getPastCampaigns(...aids),
                this.campaignService.getTradeShowReports(...aids),
                this.campaignService.getTradeShows(),
                this.userService.getLoggedInUser()
            ])),
        ).subscribe(([advertisers, campaigns, tradeShowReports, tradeShows, user]: [IAdvertiser[], ICampaign[], TradeShowReportsResponse, ITradeShow[], IUser]) => {
            this.changeColumnDefinitionsForSalesRepView();

            // Convert IAdvertiser[] to Advertiser[] and create a Map of advertisers indexed by aid
            const advertiserMap = new Map(advertisers.map(advertiser => [advertiser.aid, new Advertiser(advertiser)]));
            // Convert ICampaign[] to Campaign[] and set the advertiser property of each campaign
            campaigns = campaigns.map(campaign => new Campaign({
                ...campaign,
                advertiser: advertiserMap.get(campaign.aid)
            }));
            this.#campaignsSubject.next(new CampaignList(...campaigns));
            this.tradeShowReportsSubject.next(tradeShowReports);
            this.tradeShowsSubject.next(tradeShows);
            this.tradeShows = tradeShows;

            this.#breadcrumbsSubject.next([
                { label: `${user.firstName} ${user.lastName}`, url: '' },
                { label: 'My Advertisers Past Campaigns', url: '' },
            ]);
        });
        this.subscriptions.push(salesRepViewSubscription);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    handleIndexChange(index: number): void {
        const fragment = `tab${index + 1}`;
        void this.router.navigate([], { fragment, relativeTo: this.route });
    }

    handleTabChange() {
        this.tableComponents.forEach((tableComponent: WrapperTableComponent<Campaign>) => {
            tableComponent.resizeFormFields();
        });
    }

    protected changeColumnDefinitionsForSalesRepView(): void {
        this.emailColumns = ['advertiserName', ...this.emailColumns];
        this.socialMediaColumns = ['advertiserName', ...this.socialMediaColumns];
        this.webColumns = ['advertiserName', ...this.webColumns];
        this.ebookColumns = ['advertiserName', ...this.ebookColumns];
        this.webinarColumns = ['advertiserName', ...this.webinarColumns];
        this.printColumns = ['advertiserName', ...this.printColumns];
        this.boothScanColumns = ['advertiserName', ...this.boothScanColumns];
        this.gpSlColumns = ['advertiserName', ...this.gpSlColumns];
        this.facilitiesAndInfraColumns = ['advertiserName', ...this.facilitiesAndInfraColumns];
    }
}
